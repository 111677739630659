import React, { useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';
import Blog from '@components/Blog/Blog';
import track from '@src/tracking/track';
import useSaveUtmParamsToLocalStorage from '@src/hooks/useSaveUtmParamsToLocalStorage';
import { BlogQuery, BlogQueryVariables } from '../../graphql-types';
import PageWrapper from './shared/PageWrapper';

const TRACKING_IDENTIFIER = 'blog';

const BlogOverview = ({
  data,
  pageContext,
}: PageProps<BlogQuery, BlogQueryVariables>) => {
  const articles = data?.articles
    ? data.articles.edges.map((edge) => edge.node)
    : [];
  const config = data?.blogConfig;
  const title = config?.title?.localized || 'Collato Blog';
  useSaveUtmParamsToLocalStorage();
  useEffect(() => {
    track({
      page: {
        categorySlug: 'blog',
        pageSlug: data.blogConfig?.slug?.localized,
      },
    });
  }, [data?.blogConfig?.slug?.localized]);

  return (
    // TODO: Set up blog meta data & navigation bar in Sanity
    <PageWrapper
      locale={pageContext.locale}
      identifier={TRACKING_IDENTIFIER}
      navigationBar={config?.navigationBar}
      metadata={config?.metadata}
      announcementId={data?.blogConfig?.navigationBar?.announcement?._id}
      footerBar={data?.sanityFooterBar}
    >
      <Blog title={title} articles={articles} />
    </PageWrapper>
  );
};

export const query = graphql`
  query Blog(
    $id: String!
    $locale: String!
    $currentDate: Date!
    $footerBarId: String!
  ) {
    sanityFooterBar(_id: { eq: $footerBarId }) {
      ...FooterBar
    }
    blogConfig: sanityBlogConfig(_id: { eq: $id }) {
      title {
        localized(locale: $locale)
      }
      slug {
        localized(locale: $locale)
      }
      metadata {
        ...SEO
      }
      navigationBar {
        ...Header
      }
    }
    articles: allSanityArticle(
      filter: { blog: { _id: { eq: $id } }, publishedAt: { lte: $currentDate } }
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          _id
          _key
          title {
            localized(locale: $locale)
          }
          titleImage {
            ...GatsbyImage
          }
          summary {
            localized(locale: $locale)
          }
          author {
            firstName
            lastName
            image {
              asset {
                gatsbyImageData(width: 50, placeholder: BLURRED)
              }
            }
          }
          publishedAt
          slug {
            localized(locale: $locale)
          }
          redirects
        }
      }
    }
  }
`;

// ts-prune-ignore-next
export default BlogOverview;
