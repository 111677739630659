import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BlogQuery } from 'graphql-types';
import { DeepExtractTypeSkipArrays } from '../../../types/extract-types';
import {
  ArticleAuthor,
  ArticleAuthorWrapper,
  ArticleContentPreview,
  ArticleImageWrapper,
  ArticleMetaWrapper,
  ArticlePreviewWrapper,
  ArticlePublicationInfo,
  ArticleSummary,
  ArticleTitle,
} from './ArticlePreviewStyled';

const ArticlePreview: React.FC<
  DeepExtractTypeSkipArrays<BlogQuery, ['articles', 'edges', 'node']>
> = (article) => {
  const { titleImage, title, summary, author, publishedAt, slug } = article;
  return (
    <ArticlePreviewWrapper>
      <Link to={slug.localized}>
        <ArticleImageWrapper>
          {titleImage.asset?.gatsbyImageData && (
            <GatsbyImage image={titleImage.asset?.gatsbyImageData} alt="" />
          )}
        </ArticleImageWrapper>

        <ArticleContentPreview>
          <ArticleTitle>{title.localized}</ArticleTitle>
          <ArticleSummary>{summary.localized}</ArticleSummary>

          <ArticleMetaWrapper>
            {author.image?.asset?.gatsbyImageData && (
              <GatsbyImage image={author.image.asset.gatsbyImageData} alt="" />
            )}
            <ArticleAuthorWrapper>
              <ArticleAuthor>
                {author.firstName}
                {author.lastName && ` ${author.lastName}`}
              </ArticleAuthor>
              <ArticlePublicationInfo>{publishedAt}</ArticlePublicationInfo>
            </ArticleAuthorWrapper>
          </ArticleMetaWrapper>
        </ArticleContentPreview>
      </Link>
    </ArticlePreviewWrapper>
  );
};

export default ArticlePreview;
