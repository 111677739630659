import React from 'react';
import { BlogQuery } from 'graphql-types';
import { useHeaderContext } from '@components/Layout/Header/headerContext';
import { DeepExtractTypeSkipArrays } from '../../types/extract-types';
import { BlogArticles, BlogTitle, BlogWrapper } from './BlogStyled';
import ArticlePreview from './ArticlePreview/ArticlePreview';

interface IBlogProps {
  title: string;
  articles: DeepExtractTypeSkipArrays<
    BlogQuery,
    ['articles', 'edges', 'node']
  >[];
}

const Blog: React.FC<IBlogProps> = ({ title, articles }) => {
  const {
    state: { announcementBarVisible },
  } = useHeaderContext();

  return (
    <BlogWrapper announcementBarVisible={announcementBarVisible}>
      <BlogTitle>{title}</BlogTitle>
      <BlogArticles>
        {articles.map((article) => (
          // eslint-disable-next-line react/jsx-key
          <ArticlePreview {...article} />
        ))}
      </BlogArticles>
    </BlogWrapper>
  );
};
export default Blog;
